import React from 'react';

import '../css/Responsabilidad.css';

import Inicio4 from '../assets/inicio/inicio4.jpg';

function Responsabilidad() {
  return (
    <div className="op-body">
      <div className="op-section op-nosotros">
        <div className="op-barra">
          <span className="op-title">Responsabilidad social</span>
          <p>
            Pacific Alliance Trading Company S.A.C. fue fundada por productores
            de cacao de del VRAEM, unidos por objetivos comunes, como es la
            sostenibilidad de la cadena de valor del cacao en que se encuentran
            inmersas, con el fin de lograr los objetivos trazados se
            logr&oacute; importantes acuerdos de cooperaci&oacute;n comercial
            con asociaciones y cooperativas de la zona, desarrollamos un trabajo
            de campo que consisti&oacute; en concientizar a los productores y
            sus organizaciones sobre la importancia de cultivar especies de
            cacao nativo y tener procesos de fermento y secado definidos con el
            fin de obtener un producto de alta calidad y mejorar sus ingresos,
            sac&aacute;ndolos del c&iacute;rculo de la comercializaci&oacute;n
            de vol&uacute;menes por la producci&oacute;n artesanal y
            org&aacute;nica. Una caracter&iacute;stica relevante de las
            organizaciones es la habilidad, esfuerzo, inteligencia y
            pasi&oacute;n para producir cacao, adem&aacute;s de que el valle
            cuenta con suelos f&eacute;rtiles. Una debilidad, es el poco
            conocimiento del negocio cacaotero y limitantes para la
            gesti&oacute;n asociativa. Es importante conocer que se encuentran
            en un espacio donde la informalidad a&uacute;n perdura por el
            desarrollo de las actividades il&iacute;citas, la confianza que es
            el elemento fundamental para dinamizar la acci&oacute;n colectiva
            a&uacute;n es muy pobre.
          </p>
        </div>
        <div className="op-barra">
          <div className="imagen"></div>
        </div>
      </div>
    </div>
  );
}

export default Responsabilidad;
